<template>
  <div class="mainform">
    <div class="mainHeader">查看返修单<span @click="back" class="back">返回></span></div>
    <div class="form">
    <el-form class="content bascform" ref="form" :model="form"  label-width="125px">
      <div class="col col4">
        <el-form-item label="返修单号">
          <span class="onlyText">
            {{form.repairOrders}}
          </span>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="公司名称">
          <span class="onlyText">
            {{form.companyCode+'-'+form.companyName}}
          </span>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="供应商">
          <span class="onlyText">
            {{form.supplierCode+'-'+form.supplierName}}
          </span>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="状态">
          <span class="onlyText">
            {{form.status==1?'待供应商确认':form.status==2?'待我修改':form.status==3?'待库房发货':form.status==4?'待供应商收货'
            :form.status==5?'待供应商发货':form.status==6?'待库房收货':form.status==7?'待我确认':'已完成'}}
          </span>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="返修地址" prop="repairAddress" :rules="{ required: true, message: '请选择返修地址', trigger: 'blur' }">
          <el-select v-model="form.repairAddress" @change="repairAddresschange"  placeholder="请选择">
            <el-option v-for="(item,index) in repairOptions" :key="index" :label="item.label" :value="item.label"> </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="收货地址" prop="receivingAddress" :rules="{ required: true, message: '请选择收货地址', trigger: 'change' }">
          <el-select v-model="form.receivingAddress" @change="receivingAddresschange"  placeholder="请选择">
            <el-option v-for="(item,index) in receivingOptions" :key="index" :label="item.label" :value="item.label"> </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="返修类型" prop="repairType" :rules="{ required: true, message: '请选择返修类型', trigger: 'change' }">
          <el-select v-model="form.repairType" placeholder="请选择">
            <el-option :key="index" v-for="(itme,index) in repairTypeOption" :label="itme.label" :value="itme.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="备注">
          <el-input  v-model="form.bz"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" v-if="form.status==2">
        <el-form-item label="供应商拒绝原因">
          <span class="onlyText">
            {{form.refusedText}}
          </span>
        </el-form-item>
      </div>
      <div class="col col4" v-if="form.status==3">
        <el-form-item label="RMA号">
          <span class="onlyText">
            {{form.rma}}
          </span>
          <!-- <el-input  v-model="form.rma"></el-input> -->
        </el-form-item>
      </div>
      <div class="col col4" v-if="form.status==3">
        <el-form-item label="供应商备注">
          <span class="onlyText">
            {{form.supplierBz}}
          </span>
          <!-- <el-input  v-model="form.supplierBz"  ></el-input> -->
        </el-form-item>
      </div>
      <div class="forrepair">
        <el-form-item label="待修部件" >
          <!-- v-if="form.status==2" -->
          <el-button  size="small" type="primary"  @click="ChooseSn">选择部件</el-button>
        </el-form-item>
      </div>
    </el-form>
      <div class="content bascform">
        <div class="tableList" >
          <div class="table">
            <el-table  style="width: 100%" ref="formList" :data="formList">
              <!-- <el-table-column prop="sequenceNum" label="序号" :show-overflow-tooltip="true"></el-table-column> -->
              <el-table-column prop="materialCode" label="物料编号" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="materialDesc"  label="物料描述" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="materialNum" label="数量/单位" :show-overflow-tooltip="true">
                <template #default="scope">
                  <el-input v-model="scope.row.materialNum" @input="(val)=>{scope.row.materialNum}" placeholder="请输入"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="plant" label="工厂" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="storageLand" label="库位" :show-overflow-tooltip="true"></el-table-column>
              <!-- <el-table-column prop=""  label="查看SN">
                <template #default="scope">
                  <el-button size="mini" type="text" @click="handEdit(scope.$index, scope.row)">SN</el-button>
                </template>
              </el-table-column> -->
              <el-table-column prop="outboundBase"  label="出库基地"></el-table-column>
              <el-table-column prop="" label="操作" >
                <template #default="scope">
                  <el-button size="mini" type="text" @click="deleteRow(scope.$index,scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
  </div>
  <!--  -->
  <div class="action" v-if="form.status==1 || form.status==3">
    <el-button class="submit" type="primary" size="medium" @click="edit">编辑提交</el-button>
  </div>
  <!-- 待我确认 状态为 6 可取消 提交 -->
  <div class="action" v-if="form.status==6">
    <el-button class="submit" type="primary" size="medium" @click="affirmBtn">确认</el-button>
  </div>
  <div class="action" v-if="form.status==2">
    <el-button class="submit" type="primary" size="medium" @click="submitBtn">提交</el-button>
  </div>
  <!-- <dialog-box  dialogWidth="826px" :dialogShow="sNdialogShow" @handleClose='handleClose' title="查看SN" componentName="SNDialog"
:buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'handleAffirm', type: 'primary' }]" ></dialog-box> -->

  <dialog-box  dialogWidth="826px" :form="form" ref="dialoginfo" :dialogShow="dialogShow" @handleClose='handleClose' @confirmAdd=confirmAdd
  title="选择部件"  componentName="ChangeDialog" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]"></dialog-box>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm, request } from '@/assets/js/http.js'
// import { requestForm } from '@/assets/js/http.js'
export default {
  name: 'RepairDetail',
  components: Component.components,
  data: function () {
    return {
      repairOptions: [],
      receivingOptions: [], // 收货
      formList: [],
      dialogShow: false,
      formDisabled: true,
      sNdialogShow: false, // 查看SN
      form: {},
      repairType: '',
      repairTypeOption: [
        { label: 'RMA返修', value: '1' },
        { label: 'DOA返修', value: '2' }
      ],
      materialCode: '',
      materialDesc: '',
      materialNum: '',
      outboundBase: '',

      bz: '',
      materialCodeName: '',
      companyCodeName: '',
      repairAddress: '',
      receivingAddress: '',

      companyCode: '',
      companyName: '',
      supplierCode: '',
      supplierName: ''
    }
  },
  created () {
    this.receivingAddresschange()
    this.repairAddresschange()
    this.partslist()
  },
  methods: {
    partslist () {
      var id = this.$route.query.id
      requestForm('/api/repair/repair/queryRepairDetails?id=' + id, 'get').then((res) => {
        if (res.code === '200') {
          this.form = res.data.repairDto
          this.formList = res.data.repairDtos
        }
      })
    },
    receivingAddresschange (val) { // 返修地址
      request('/api/repair/repair/queryBaseInfo', 'get').then((res) => {
        if (res.code === '200') {
          res.data.forEach(item => {
            this.receivingOptions.push({
              label: item.baseCodeName,
              value: item.baseCodeName
            })
          })
        }
      })
    },
    repairAddresschange (val) { // 返修地址
      request('/api/repair/repair/queryBaseInfo', 'get').then((res) => {
        if (res.code === '200') {
          for (const item of res.data) {
            this.repairOptions.push({
              label: item.baseCodeName,
              value: item.baseCodeName
            })
          }
        }
      })
    },
    confirmAdd () { // 弹框确认按钮
      var newListData = this.$refs.dialoginfo.$refs.content.$refs.table.selectData
      if (newListData.length > 0) {
        this.formList = this.$refs.dialoginfo.$refs.content.$refs.table.selectData
        this.dialogShow = false
      } else {
        this.$message({
          message: '请选择要添加的部件',
          type: 'warning'
        })
      }
    },
    edit () { // 编辑后提交
      var repairDetailsDtos = []
      var formList = this.formList
      formList.forEach(item => {
        this.materialCode = item.materialCode
        this.materialDesc = item.materialDesc
        this.materialNum = item.materialNum
        this.outboundBase = item.outboundBase
        var repairDetailsData = {
          materialCode: this.materialCode,
          materialDesc: this.materialDesc,
          materialNum: this.materialNum,
          outboundBase: this.outboundBase
        }
        repairDetailsDtos.push(repairDetailsData)
      })
      var repairobj = this.form
      this.bz = repairobj.bz
      this.materialCodeName = repairobj.materialCodeName
      this.companyCodeName = repairobj.companyCodeName
      this.repairAddress = repairobj.repairAddress
      this.receivingAddress = repairobj.receivingAddress

      this.companyCode = repairobj.companyCode
      this.companyName = repairobj.companyName
      this.supplierCode = repairobj.supplierCode
      this.supplierName = repairobj.supplierName

      var repairDto = {
        id: this.$route.query.id,
        bz: this.bz,
        materialCodeName: this.supplierCode + '-' + this.supplierName,
        companyCodeName: this.companyCode + '-' + this.companyName,
        repairAddress: this.repairAddress,
        receivingAddress: this.receivingAddress
      }
      const obj = {
        repairDto: repairDto,
        repairDetailsDtos: repairDetailsDtos
      }
      request('/api/repair/repair/updateEditRepair', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        }
        this.$router.push({
          path: '/InventoryDelivery/PurchaseRepair'
        })
      })
    },
    affirmBtn () { // 确认
      var id = this.$route.query.id
      requestForm('/api/repair/repair/updateAffirmSetem?id=' + id, 'get').then((res) => {
        if (res.code === '200') {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push('/InventoryDelivery/PurchaseRepair')
        }
      })
    },
    submitBtn () { // 待我修改   提交按钮
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      var repairDetailsDtos = []
      var formList = this.formList
      formList.forEach(item => {
        this.materialCode = item.materialCode
        this.materialDesc = item.materialDesc
        this.materialNum = item.materialNum
        this.outboundBase = item.outboundBase
        var repairDetailsData = {
          materialCode: this.materialCode,
          materialDesc: this.materialDesc,
          materialNum: this.materialNum,
          outboundBase: this.outboundBase
        }
        repairDetailsDtos.push(repairDetailsData)
      })
      var repairobj = this.form
      this.bz = repairobj.bz
      this.materialCodeName = repairobj.materialCodeName
      this.companyCodeName = repairobj.companyCodeName
      this.repairAddress = repairobj.repairAddress
      this.receivingAddress = repairobj.receivingAddress

      this.companyCode = repairobj.companyCode
      this.companyName = repairobj.companyName
      this.supplierCode = repairobj.supplierCode
      this.supplierName = repairobj.supplierName

      var repairDto = {
        id: this.$route.query.id,
        bz: this.bz,
        materialCodeName: this.supplierCode + '-' + this.supplierName,
        companyCodeName: this.companyCode + '-' + this.companyName,
        repairAddress: this.repairAddress,
        receivingAddress: this.receivingAddress
      }
      const obj = {
        repairDto: repairDto,
        repairDetailsDtos: repairDetailsDtos
      }
      request('/api/repair/repair/updateWhenIRepair', 'POST', obj).then((res) => {
        if (res.code === '200') {
        }
        this.$router.push({
          path: '/InventoryDelivery/PurchaseRepair'
        })
      })
    },
    ChooseSn () {
      this.dialogShow = true
    },
    handleClose () {
      this.dialogShow = false
      this.sNdialogShow = false
    },
    handEdit () {
      this.sNdialogShow = true
    },
    deleteRow (index, data) {
      this.$confirm('确认删除？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var obj = {
          id: data.id,
          materialCode: data.materialCode
        }
        requestForm('/api/repair/repair/deleteRepairWarehouse', 'post', obj).then((Response) => {
          if (Response.code === '200') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
          }
          this.partslist()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  @import '@/assets/css/tableList.scss';
.mainform .form .bascform {
    padding: 0px 0;
}
.tableList{
  padding: 0px 0px 0px 135px;
}
.forrepair{
    padding: 10px;
}
.refuse{
  padding: 10px;
}
.action{
  margin-top: 35px;
}
</style>
